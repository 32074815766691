const cache = {}

/**
 * @param {string} url
 * @returns {string}
 */
const useCachedUrl = (url) => {
  const baseUrl = url.split('?')[0]

  if (!cache[baseUrl]) {
    cache[baseUrl] = url
  }

  return cache[baseUrl]
}

export default useCachedUrl
