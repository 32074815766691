import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setWorldRotation } from '../actions'

const useWorldRotation = (initialRotation) => {
  const dispatch = useDispatch()

  useEffect(() => {
    dispatch(setWorldRotation(initialRotation))
  }, [dispatch, initialRotation])
}

export default useWorldRotation
