import { memo } from 'react'
import { useSelector } from 'react-redux'
import PropTypes from 'prop-types'

import { AnnotationType } from '@tabeeb/enums'

import { IconButton, ListItemIcon, ListItemText, Tooltip, Box } from '@mui/material'
import { EditOutlined, DeleteOutlined, VisibilityOffOutlined, VisibilityOutlined } from '@mui/icons-material'

import { getAnnotationChild } from '@tabeeb/modules/annotations/selectors'
import { useAnnotationVisibility } from '@tabeeb/modules/pointCloud/hooks'

import AnnotationIcon from './AnnotationIcon'

const getDefaultAnnotationName = (type) => {
  if (type === AnnotationType.Area) {
    return 'Area'
  }

  if (type === AnnotationType.AIHotspot) {
    return 'Hotspot'
  }

  if (type === AnnotationType.Box) {
    return 'Box'
  }

  if (type === AnnotationType.Cylinder) {
    return 'Cylinder'
  }

  if (type === AnnotationType.Line) {
    return 'Line'
  }

  if (type === AnnotationType.Distance) {
    return 'Distance'
  }

  if (type === AnnotationType.Polyline) {
    return 'Polyline'
  }

  if (type === AnnotationType.Height) {
    return 'Height'
  }

  if (type === AnnotationType.Model) {
    return 'Model'
  }

  return 'Annotation'
}

export const getUniqueAiObjectNameFromAnnotation = (annotation) => {
  const text = annotation?.Text
  if (!text) {
    return null
  }

  const parts = text.split(', ')
  const className = parts[0]?.split(':')[1]?.trim()
  const objectName = parts[1]?.split(':')[1]?.trim()

  return objectName || className
}

const Annotation = ({ annotation, onDelete, onEdit, onToggleVisibility }) => {
  const { visible } = useAnnotationVisibility({ id: annotation.Id })
  const hotspotAnnotation = useSelector((state) => getAnnotationChild(state, { Id: annotation.Id }))
  const uniqueAiObjectAnnotation = useSelector((state) => getAnnotationChild(state, { Id: hotspotAnnotation?.Id }))

  return (
    <Box display='flex' alignItems='center' flexGrow={1} minWidth={0}>
      <ListItemIcon>
        <AnnotationIcon color={annotation.Color} type={annotation.Type} />
      </ListItemIcon>

      <ListItemText
        primary={
          annotation.Name ||
          getUniqueAiObjectNameFromAnnotation(uniqueAiObjectAnnotation) ||
          getDefaultAnnotationName(annotation.Type)
        }
      />

      <Box display='flex' alignItems='center'>
        {onToggleVisibility && (
          <Tooltip title={visible ? 'Hide' : 'Show'}>
            <IconButton size='small' onClick={() => onToggleVisibility(annotation, !visible)}>
              {visible ? <VisibilityOutlined /> : <VisibilityOffOutlined />}
            </IconButton>
          </Tooltip>
        )}

        {onEdit && (
          <Tooltip title='Edit'>
            <IconButton size='small' onClick={() => onEdit(annotation)}>
              <EditOutlined />
            </IconButton>
          </Tooltip>
        )}

        {onDelete && (
          <Tooltip title='Delete'>
            <IconButton size='small' onClick={() => onDelete(annotation.Id)}>
              <DeleteOutlined />
            </IconButton>
          </Tooltip>
        )}
      </Box>
    </Box>
  )
}

Annotation.propTypes = {
  annotation: PropTypes.shape({
    Id: PropTypes.number.isRequired,
    Color: PropTypes.string.isRequired,
    Name: PropTypes.string,
    Type: PropTypes.oneOf(Object.values(AnnotationType)).isRequired,
  }).isRequired,
  onDelete: PropTypes.func,
  onEdit: PropTypes.func,
  onToggleVisibility: PropTypes.func,
}

export default memo(Annotation)
