/* eslint-disable no-param-reassign */
import { useEffect } from 'react'

import { Matrix4, Mesh, MeshBasicMaterial } from 'three'
import {
  CENTER,
  INTERSECTED,
  NOT_INTERSECTED,
  acceleratedRaycast,
  computeBoundsTree,
  disposeBoundsTree,
} from 'three-mesh-bvh'

export default (object, pointsGeometry) => {
  useEffect(() => {
    if (!object || !pointsGeometry) {
      return () => {}
    }

    const bvhGeometry = pointsGeometry
    bvhGeometry.computeBoundsTree = computeBoundsTree
    bvhGeometry.disposeBoundsTree = disposeBoundsTree

    const pointsCount = pointsGeometry.attributes.position.count
    const indices = []
    for (let i = 0; i < pointsCount; i++) {
      indices.push(i, i, i)
    }

    bvhGeometry.setIndex(indices)

    const bvhMesh = new Mesh(bvhGeometry, new MeshBasicMaterial({ color: 0xff0000 }))
    bvhMesh.raycast = acceleratedRaycast

    bvhMesh.geometry.computeBoundsTree({ mode: CENTER })

    object.raycast = function raycast(raycaster, intersects) {
      const inverseMatrix = new Matrix4()
      inverseMatrix.copy(bvhMesh.matrixWorld).invert()
      raycaster.ray.applyMatrix4(inverseMatrix)

      const { threshold } = raycaster.params.Points

      const localThreshold = threshold / ((bvhMesh.scale.x + bvhMesh.scale.y + bvhMesh.scale.z) / 3)
      const localThresholdSq = localThreshold * localThreshold

      const { ray } = raycaster
      let closestDistance = Infinity
      bvhMesh.geometry.boundsTree.shapecast({
        boundsTraverseOrder: (box) => {
          return box.distanceToPoint(ray.origin)
        },
        intersectsBounds: (box, isLeaf, score) => {
          if (score > closestDistance) {
            return NOT_INTERSECTED
          }

          box.expandByScalar(localThreshold)

          return ray.intersectsBox(box) ? INTERSECTED : NOT_INTERSECTED
        },
        intersectsTriangle: (triangle) => {
          const distancesToRaySq = ray.distanceSqToPoint(triangle.a)
          if (distancesToRaySq < localThresholdSq) {
            const distanceToPoint = ray.origin.distanceTo(triangle.a)
            if (distanceToPoint < closestDistance) {
              closestDistance = distanceToPoint

              intersects.push({
                point: triangle.a.clone(),
                distance: distanceToPoint,
                object: this,
              })
            }
          }
        },
      })
    }

    return () => {}
  }, [pointsGeometry, object])
}
