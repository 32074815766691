import { useState, useMemo } from 'react'

import _ from 'lodash'
import { MathUtils } from 'three'

import { useFrame, useThree } from '@react-three/fiber'

const TAU = Math.PI * 2

const normalizeAngle = (angle) => {
  return MathUtils.euclideanModulo(Math.PI - angle, TAU)
}

const useCameraAzimuth = (THROTTLE_MS = 250) => {
  const controls = useThree((state) => state.controls)
  const [azimuth, setAzimuth] = useState(0)

  const setThrottled = useMemo(
    () =>
      _.throttle((cameraAzimuth) => {
        setAzimuth(cameraAzimuth)
      }, THROTTLE_MS),
    [THROTTLE_MS]
  )

  useFrame(() => {
    const cameraAzimuth = MathUtils.radToDeg(normalizeAngle(controls?.azimuthAngle || 0))
    setThrottled(cameraAzimuth)
  })

  return azimuth
}

export default useCameraAzimuth
