import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { setBoundingBox } from '../actions'

export default (id, box) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (!box) {
      return
    }

    dispatch(setBoundingBox({ id, box }))
  }, [id, box, dispatch])
}
