import { takeLatest, all, put, select } from 'redux-saga/effects'

import { galleryActions } from '@tabeeb/modules/gallery'
import { getSelectedGalleryItem } from '@tabeeb/modules/gallery/selectors'

import * as aiActions from '../actions'
import * as notificationActions from '../../notification/actions'

function* getPageUniqueAIObjects() {
  const page = yield select(getSelectedGalleryItem)

  const objectsIds = page.annotations.map((object) => object.UniqueAIObjectId).filter((id) => !!id)
  if (!objectsIds.length) {
    return
  }

  yield put(aiActions.getUniqueAiObjectsByIds.request({ uniqueAiObjectIds: objectsIds }))
}

function* onGetUniqueAiObjectsFailed(action) {
  yield put(notificationActions.onAddErrorNotification({ message: 'Failed to load objects.' }))
}

function* onGetUniqueAiObjectForEditFailed(action) {
  yield put(notificationActions.onAddErrorNotification({ message: 'Failed to load the object.' }))
}

function* getUniqueAIObjects() {
  yield all([
    takeLatest(galleryActions.selectGalleryItem, getPageUniqueAIObjects),
    takeLatest(aiActions.getUniqueAiObjects.failed, onGetUniqueAiObjectsFailed),
    takeLatest(aiActions.getUniqueAiObjectForEditFailed, onGetUniqueAiObjectForEditFailed),
  ])
}

export default getUniqueAIObjects
