import { useEffect } from 'react'
import PropTypes from 'prop-types'

import { MathUtils } from 'three'

import { Axes } from '../constants'

const useModelRotation = ({ model, tilt, azimuth }) => {
  useEffect(() => {
    if (!model) {
      return
    }

    model.quaternion.set(0, 0, 0, 1)
    model.rotateOnAxis(Axes.X, MathUtils.degToRad(-tilt))
    model.rotateOnWorldAxis(Axes.Y, MathUtils.degToRad(-azimuth))
  }, [azimuth, model, tilt])
}

useModelRotation.propTypes = {
  azimuth: PropTypes.number.isRequired,
  tilt: PropTypes.number.isRequired,
}

export default useModelRotation
