import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { openHotspotDialog } from '@tabeeb/modules/annotations/actions'
import { getHotspotDialogAnnotationId } from '@tabeeb/modules/annotations/selectors'

export default (id) => {
  const dispatch = useDispatch()

  const open = useSelector((state) => getHotspotDialogAnnotationId(state) === id)

  const onOpen = useCallback(() => {
    dispatch(openHotspotDialog({ annotationId: id }))
  }, [dispatch, id])

  return {
    open,
    onOpen,
  }
}
