import { useCallback, useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { setSelectedAnnotationsIds, resetSelectedAnnotationsIds } from '@tabeeb/modules/annotations/actions'
import { getIsAnnotationSelected } from '@tabeeb/modules/annotations/selectors'

const ESC_KEY_CODE = 27

export default ({ id }) => {
  const dispatch = useDispatch()
  const selected = useSelector((state) => getIsAnnotationSelected(state, { Id: id }))

  useEffect(() => {
    if (!selected) {
      return () => {}
    }

    const resetSelection = ({ keyCode }) => {
      if (keyCode !== ESC_KEY_CODE) {
        return
      }

      dispatch(resetSelectedAnnotationsIds())
    }

    window.addEventListener('keyup', resetSelection)

    return () => {
      window.removeEventListener('keyup', resetSelection)
    }
  }, [selected, dispatch])

  const onSelect = useCallback(
    (e) => {
      e.stopPropagation()
      dispatch(setSelectedAnnotationsIds([id]))
    },
    [dispatch, id]
  )

  return useMemo(
    () => ({
      selected,
      onSelect,
    }),
    [selected, onSelect]
  )
}
