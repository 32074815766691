import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { Box3 } from 'three'

import { setBoundingBox } from '../actions'

export default (id, object, enabled = true) => {
  const dispatch = useDispatch()

  useEffect(() => {
    if (!enabled) {
      return
    }

    dispatch(setBoundingBox({ id, box: new Box3().setFromObject(object) }))
  }, [dispatch, enabled, id, object])
}
