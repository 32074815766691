import { useCallback, useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { AnnotationType } from '@tabeeb/enums'
import { updateContentAnnotation } from '@tabeeb/modules/annotations/actions'

import { getWorldRotationCompensation } from '../selectors'

const ESC_KEY = 27

const useTransformControls = ({ annotation }) => {
  const dispatch = useDispatch()

  const worldRotationCompensation = useSelector(getWorldRotationCompensation)

  const [object, setObject] = useState(null)
  const [enabled, setEnabled] = useState(false)

  const onSelect = useCallback((e) => {
    e.stopPropagation()
    setObject(e.eventObject)
    setEnabled((prevEnabled) => !prevEnabled)
  }, [])

  useEffect(() => {
    if (!enabled) {
      return () => {}
    }

    const disableTransformation = ({ keyCode }) => {
      if (keyCode !== ESC_KEY) {
        return
      }

      setEnabled(false)
    }

    window.addEventListener('keydown', disableTransformation)

    return () => {
      window.removeEventListener('keydown', disableTransformation)
    }
  }, [enabled])

  const onUpdated = (e) => {
    const newPosition = e.target.object.position.clone().applyEuler(worldRotationCompensation)

    if (annotation.Type === AnnotationType.Model) {
      dispatch(
        updateContentAnnotation({
          annotation: {
            ...annotation,
            Anchor: { X: newPosition.x, Y: newPosition.y, Z: newPosition.z },
            Url: annotation.Url.split('?')[0],
          },
        })
      )
    }
  }

  return {
    enabled,
    object,
    onSelect,
    onUpdated,
  }
}

export default useTransformControls
