import PropTypes from 'prop-types'
import Dropzone from 'react-dropzone'

import { Clear, CloudUpload, FileUpload } from '@mui/icons-material'
import { Button, Grid, IconButton, Typography, Box } from '@mui/material'

import { Bold } from '@tabeeb/shared/uikit'
import { BYTES_IN_MEGABYTES } from '@tabeeb/shared/constants'
import { mimeTypes } from '@tabeeb/shared/uikit/constants'

/**
 * Drag and drop files element
 * @param {Object.<string, string[]>[]} acceptMimeFormats - Array of accepted mime formats in object structure: key - MIME type, value - array of file extensions, started with dot. See: {@link https://developer.mozilla.org/en-US/docs/Web/HTTP/Basics_of_HTTP/MIME_types/Common_types}
 * @param file
 * @param acceptedFormatDisplayStrings
 * @param fileUploadButtonText
 * @param placeholder
 * @param onDropAccepted
 * @param onClearState
 * @param maximumSizeOfMegabytes
 */
const DragAndDropFiles = ({
  acceptMimeFormats,
  file,
  acceptedFormatDisplayStrings,
  fileUploadButtonText,
  placeholder,
  onDropAccepted,
  onClearState,
  maximumSizeOfMegabytes,
  spacing,
}) => {
  return (
    <Dropzone
      maxFiles={1}
      noClick
      noKeyboard
      maxSize={BYTES_IN_MEGABYTES * maximumSizeOfMegabytes}
      onDropAccepted={onDropAccepted}
      accept={acceptMimeFormats}
    >
      {({ getRootProps, getInputProps, isFocused, isDragAccept, isDragReject, open, fileRejections }) => (
        <Grid
          container
          wrap='nowrap'
          direction='column'
          spacing={spacing}
          sx={{
            p: 2,
            borderRadius: 1,
            alignItems: 'center',
            borderWidth: 2,
            borderColor: isFocused ? '#2196f3' : isDragAccept ? '#00e676' : isDragReject ? '#ff1744' : '#eeeeee',
            borderStyle: 'dashed',
            backgroundColor: '#fafafa',
            color: '#bdbdbd',
            outline: 'none',
            transition: 'border .24s ease-in-out',
          }}
          {...getRootProps()}
        >
          <input {...getInputProps()} />
          <CloudUpload />
          <Grid item>
            <Bold>{placeholder}</Bold>
          </Grid>
          <Grid item>
            {file ? (
              <Box sx={{ display: 'flex' }}>
                <Typography sx={{ color: 'black' }}>{file.path}</Typography>
                <IconButton size='small' onClick={onClearState}>
                  <Clear />
                </IconButton>
              </Box>
            ) : (
              <Button variant='outlined' onClick={open} startIcon={<FileUpload />}>
                {fileUploadButtonText}
              </Button>
            )}
          </Grid>
          {fileRejections.length > 0 && (
            <Grid item>
              <Typography sx={{ color: '#EB3D26' }}>
                {fileRejections[0].errors[0].code === 'file-too-large'
                  ? `File is too large, maximum size is ${maximumSizeOfMegabytes}MB`
                  : 'Unsupported format'}
              </Typography>
            </Grid>
          )}
          <Grid item sx={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
            <Typography variant='caption'>Supported formats: {acceptedFormatDisplayStrings.join(', ')}</Typography>
            <Typography variant='caption'>Maximum size: {maximumSizeOfMegabytes}MB</Typography>
          </Grid>
        </Grid>
      )}
    </Dropzone>
  )
}

DragAndDropFiles.defaultProps = {
  acceptMimeFormats: {
    [mimeTypes.jpeg]: ['.jpeg', '.jpg'],
    [mimeTypes.png]: [],
    [mimeTypes.bmp]: [],
  },
  acceptedFormatDisplayStrings: ['png', 'jpg', 'jpeg', 'bmp'],
  placeholder: 'Please select a certificate photo to upload',
  fileUploadButtonText: 'Upload photo',
  maximumSizeOfMegabytes: 30,
  spacing: 1,
}

DragAndDropFiles.propTypes = {
  acceptMimeFormats: PropTypes.instanceOf(Object),
  file: PropTypes.instanceOf(File),
  acceptedFormatDisplayStrings: PropTypes.arrayOf(PropTypes.string),
  fileUploadButtonText: PropTypes.string,
  placeholder: PropTypes.string,
  onDropAccepted: PropTypes.func.isRequired,
  onClearState: PropTypes.func.isRequired,
  maximumSizeOfMegabytes: PropTypes.number,
  spacing: PropTypes.number,
}

export default DragAndDropFiles
