import { useCallback, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'

import { deleteContentAnnotations, updateContentAnnotation } from '@tabeeb/modules/annotations/actions'
import { useUpdate } from '@tabeeb/modules/shared/utils/hooks'

export default (sourceAnnotation) => {
  const dispatch = useDispatch()
  const annotationRef = useRef(sourceAnnotation)
  const [annotation, setAnnotation] = useState(sourceAnnotation)

  useUpdate(() => {
    setAnnotation(sourceAnnotation)
  }, [sourceAnnotation])

  const onAddPoint = useCallback(
    ({ index, point }) => {
      const updatedAnnotation = {
        ...annotation,
        Points: [
          ...annotation.Points.slice(0, index + 1),
          {
            X: point.x,
            Y: point.y,
            Z: point.z,
          },
          ...annotation.Points.slice(index + 1),
        ],
      }

      dispatch(updateContentAnnotation({ annotation: updatedAnnotation }))
    },
    [annotation, dispatch]
  )

  const onPointDelete = useCallback(
    ({ id }) => {
      if (annotation.Points.length <= 2) {
        dispatch(deleteContentAnnotations({ annotationIds: [annotation.Id] }))

        return
      }

      const updatedAnnotation = {
        ...annotation,
        Points: annotation.Points.filter((_, index) => index !== id),
      }

      dispatch(updateContentAnnotation({ annotation: updatedAnnotation }))
    },
    [annotation, dispatch]
  )

  const onPointUpdate = useCallback(({ id, point: updatedPoint }) => {
    setAnnotation((prevAnnotation) => {
      return {
        ...prevAnnotation,
        Points: prevAnnotation.Points.map((prevPoint, pointIndex) => {
          if (pointIndex !== id) {
            return prevPoint
          }

          return {
            X: updatedPoint.x,
            Y: updatedPoint.y,
            Z: updatedPoint.z,
          }
        }),
      }
    })
  }, [])

  const onUpdateCommit = useCallback(() => {
    dispatch(updateContentAnnotation({ annotation: annotationRef.current }))
  }, [dispatch])

  annotationRef.current = annotation

  return {
    annotation,
    onAddPoint,
    onPointDelete,
    onPointUpdate,
    onUpdateCommit,
  }
}
