import { useCallback, useEffect, useState } from 'react'

import _ from 'lodash'

export default ({ id }) => {
  const [slicesProgress, setSlicesProgress] = useState({})

  useEffect(() => {
    setSlicesProgress({})
  }, [id])

  const onProgress = useCallback(
    (url) => (event) => {
      setSlicesProgress((prevProgress) => ({ ...prevProgress, [url]: (event.loaded / event.total) * 100 }))
    },
    []
  )

  const progress = _.mean(Object.values(slicesProgress)) || 0

  return { progress, onProgress }
}
