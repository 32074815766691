import { useCallback, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { updateContentAnnotation } from '@tabeeb/modules/annotations/actions'

import { getWorldRotationCompensation } from '../selectors'
import { getGroundHeight } from '../utils/measurements'
import useModelBoundingBox from './useModelBoundingBox'

const useTransformationControls = ({ annotation }) => {
  const dispatch = useDispatch()

  const worldRotationCompensation = useSelector(getWorldRotationCompensation)
  const modelBoundingBox = useModelBoundingBox()

  const getElevation = useCallback(
    (updatedAnnotation) => {
      return updatedAnnotation.Anchor.Y - getGroundHeight(modelBoundingBox)
    },
    [modelBoundingBox]
  )

  const onTranslate = useCallback(
    (offset) => {
      const projectedOffset = offset.clone().applyEuler(worldRotationCompensation)

      const updatedAnnotation = {
        ...annotation,
        Anchor: {
          X: annotation.Anchor.X + projectedOffset.x,
          Y: annotation.Anchor.Y + projectedOffset.y,
          Z: annotation.Anchor.Z + projectedOffset.z,
        },
      }

      updatedAnnotation.Elevation = getElevation(updatedAnnotation)

      dispatch(
        updateContentAnnotation({
          annotation: updatedAnnotation,
        })
      )
    },
    [annotation, dispatch, getElevation, worldRotationCompensation]
  )

  const onRotate = useCallback(
    (newRotation) => {
      const updatedAnnotation = {
        ...annotation,
        Rotation: {
          X: newRotation.x,
          Y: newRotation.y,
          Z: newRotation.z,
        },
      }

      updatedAnnotation.Elevation = getElevation(updatedAnnotation)

      dispatch(
        updateContentAnnotation({
          annotation: updatedAnnotation,
        })
      )
    },
    [annotation, dispatch, getElevation]
  )

  const onScale = useCallback(
    (newScale) => {
      const updatedAnnotation = {
        ...annotation,
        Scale: {
          X: newScale.x,
          Y: newScale.y,
          Z: newScale.z,
        },
      }

      updatedAnnotation.Elevation = getElevation(updatedAnnotation)

      dispatch(
        updateContentAnnotation({
          annotation: updatedAnnotation,
        })
      )
    },
    [annotation, dispatch, getElevation]
  )

  return useMemo(() => ({ onTranslate, onRotate, onScale }), [onTranslate, onRotate, onScale])
}

export default useTransformationControls
