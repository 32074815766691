import { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { getIsAnnotationHighlighted } from '@tabeeb/modules/annotations/selectors'
import { addAnnotationsToHighlighted, removeAnnotationsFromHighlighted } from '@tabeeb/modules/annotations/actions'

export default ({ id }) => {
  const dispatch = useDispatch()
  const hovered = useSelector((state) => getIsAnnotationHighlighted(state, { Id: id }))

  const onHoverEnter = useCallback(
    (e) => {
      e?.stopPropagation()

      dispatch(addAnnotationsToHighlighted([id]))
    },
    [dispatch, id]
  )

  const onHoverLeave = useCallback(
    (e) => {
      e?.stopPropagation()
      dispatch(removeAnnotationsFromHighlighted([id]))
    },
    [dispatch, id]
  )

  return {
    hovered,
    onHoverEnter,
    onHoverLeave,
  }
}
