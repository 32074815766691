import PropTypes from 'prop-types'

import { useSelector } from 'react-redux'
import { useFormikContext } from 'formik'

import { AutoFixHigh } from '@mui/icons-material'
import { Button, Tooltip } from '@mui/material'

import { getScale, getUnit, getWorldRotation } from '@tabeeb/modules/pointCloud/selectors'
import { AutofillSource } from '@tabeeb/modules/artificialIntelligence/enums'
import { getUniqueAIObjectPropertyAutofillValue } from '@tabeeb/modules/artificialIntelligence/services/autofill'

import { getSelectedPageUniqueAIObjectsWithAnnotations } from '../../../selectors'

const AutofillButton = ({ uniqueAIObject }) => {
  const { values, setFieldValue } = useFormikContext()

  const worldRotation = useSelector(getWorldRotation)
  const worldScale = useSelector(getScale)
  const unit = useSelector(getUnit)
  const objects = useSelector(getSelectedPageUniqueAIObjectsWithAnnotations)

  const objectWithAnnotations = objects.find((o) => o.id === uniqueAIObject?.Id)

  const autofillProperties = values.properties.filter(
    (property) => property.isEditable && property.autofillSource !== AutofillSource.None
  )
  if (!objectWithAnnotations || autofillProperties.length === 0) {
    return null
  }

  const onAutofill = () => {
    const orderedProperties = values.properties.sort((a, b) => a.ordinal - b.ordinal)

    for (const property of autofillProperties) {
      const index = orderedProperties.findIndex((p) => p.name === property.name)

      setFieldValue(
        `properties.${index}.value`,
        getUniqueAIObjectPropertyAutofillValue(
          objectWithAnnotations,
          {
            Id: property.id,
            AiObjectPropertyId: property.aiObjectPropertyId,
            AutofillSource: property.autofillSource,
            Name: property.name,
            Value: property.value,
          },
          {
            objects,
            worldRotation,
            worldScale,
            unit,
          }
        )
      )
    }
  }

  return (
    <Tooltip title='Autofill'>
      <Button
        sx={{ position: 'absolute', top: 16, right: 24 }}
        color='primary'
        variant='contained'
        startIcon={<AutoFixHigh />}
        onClick={onAutofill}
      >
        Autofill
      </Button>
    </Tooltip>
  )
}

AutofillButton.propTypes = {
  uniqueAIObject: PropTypes.shape({
    Id: PropTypes.number.isRequired,
  }),
}

export default AutofillButton
