import { memo, useMemo } from 'react'
import PropTypes from 'prop-types'

import { AnnotationType } from '@tabeeb/enums'
import { AreaAnnotation, HeightAnnotation, DistanceAnnotation, LineAnnotation } from '@tabeeb/shared/icons'

import { CircleOutlined, LocalOfferOutlined, ViewInArOutlined } from '@mui/icons-material'

const AnnotationIcon = ({ color, type }) => {
  const Icon = useMemo(() => {
    switch (type) {
      case AnnotationType.Area:
        return AreaAnnotation
      case AnnotationType.AIHotspot:
        return LocalOfferOutlined
      case AnnotationType.Box:
        return ViewInArOutlined
      case AnnotationType.Cylinder:
        return CircleOutlined
      case AnnotationType.Distance:
        return DistanceAnnotation
      case AnnotationType.Polyline:
        return LineAnnotation
      case AnnotationType.Height:
        return HeightAnnotation
      case AnnotationType.Model:
        return ViewInArOutlined
      default:
        return DistanceAnnotation
    }
  }, [type])

  return <Icon htmlColor={color} stroke='#e6e6e6' />
}

AnnotationIcon.propTypes = {
  color: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(AnnotationType)).isRequired,
}

export default memo(AnnotationIcon)
