/* eslint-disable no-param-reassign */
import { Box3, Vector3 } from 'three'

/**
 * @param {import('three').Raycaster} raycaster
 * @param {import('three').Scene} scene
 * @returns {import('three').Intersection<Object3d<Event>>}
 */
export const getIntersection = (raycaster, scene, precision = 0.015) => {
  raycaster.params.Points.threshold = precision

  const intersections = raycaster.intersectObject(scene, true)
  if (intersections.length <= 0) {
    return null
  }

  return intersections[0]
}

/**
 * @param {import('three').Raycaster} raycaster
 * @param {import('three').Scene} scene
 * @returns {import('three').Vector3}
 */
export const getIntersectionPoint = (raycaster, scene) => {
  const point = getIntersection(raycaster, scene)?.point
  if (!point) {
    return null
  }

  if (point instanceof Vector3) {
    return point
  }

  return new Vector3(point.x, point.y, point.z)
}

/**
 * @param {import('three').Raycaster} raycaster
 * @param {import('three').Scene} scene
 * @returns {import('three').Box3}
 */
export const getZoomBox = (raycaster, scene) => {
  const intersection = getIntersection(raycaster, scene, 0.1)
  if (!intersection) {
    return null
  }

  const { distance, point } = intersection

  const boxSize = distance / 10

  return new Box3(point.clone().subScalar(boxSize), point.clone().addScalar(boxSize))
}
