import { useSelector } from 'react-redux'

import { getIsAnnotationVisible } from '@tabeeb/modules/annotations/selectors'

export default ({ id }) => {
  const visible = useSelector((state) => getIsAnnotationVisible(state, { Id: id }))

  return {
    visible,
  }
}
