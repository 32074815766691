import { createSelector } from 'reselect'
import _ from 'lodash'
import { TenantPermission } from '@tabeeb/enums'

import { getIsModelTrainingSettingEnabled } from '@tabeeb/modules/appConfigState/selectors'
import { hasTenantPermission } from '@tabeeb/modules/permissions/selectors'
import { getSelectedPageAnnotations } from '@tabeeb/modules/annotations/selectors'

export const getAiClassToDelete = (state) => state.artificialIntelligence.deleteAiClass
export const getAIObjects = (state) => state.artificialIntelligence.aiObjects
export const getAIObjectsForTags = (state) => state.artificialIntelligence.aiObjectsForTags
export const getAIObjectsWithStatistics = (state) => state.artificialIntelligence.aiObjectsWithStatistics
export const getAttachModelType = (state) => state.artificialIntelligence.attachAIModel.modelType
export const getConflictingAIObjects = (state) => state.artificialIntelligence.conflictingAIObjects.conflictingAIObjects
export const getEditAIClassDependentModelsDialogState = (state) => state.artificialIntelligence.editAIClassDialog
export const getIsAIObjectsLoading = (state) => state.artificialIntelligence.isAIObjectsLoading
export const getIsAttachModelDialogOpen = (state) => state.artificialIntelligence.attachAIModel.isDialogOpen
export const getIsConflictingAIObjectsDialogOpen = (state) =>
  state.artificialIntelligence.conflictingAIObjects.isDialogOpen
export const getIsEditUniqueAIObjectConnectionsDialogOpen = (state) =>
  state.artificialIntelligence.editUniqueAIObjectConnectionsDialog.isDialogOpen
export const getIsNLPModelsLoading = (state) => state.artificialIntelligence.isNLPModelsLoading
export const getIsNlpTagsWithAIObjectsLoading = (state) => state.artificialIntelligence.isNlpTagsWithAIObjectsLoading
export const getIsPredictionSetsLoading = (state) => state.artificialIntelligence.isPredictionSetsLoading
export const getIsTagUpdating = (state) => state.artificialIntelligence.isTagUpdating
export const getIsTenantConfigUpdating = (state) => state.artificialIntelligence.isTenantConfigUpdating
export const getIsUnattachedAIModelsLoading = (state) => state.artificialIntelligence.isUnattachedAIModelsLoading
export const getIsVQAModelsLoading = (state) => state.artificialIntelligence.isVQAModelsLoading
export const getModelTrainingPermission = (state) => hasTenantPermission(state, TenantPermission.ModelTraining)
export const getNLPModels = (state) => state.artificialIntelligence.nlpModels
export const getNLPModelToDelete = (state) => state.artificialIntelligence.deleteNLPModel
export const getNlpTagsWithAIObjects = (state) => state.artificialIntelligence.nlpTagsWithAIObjects
export const getPredictionSets = (state) => state.artificialIntelligence.predictionSets
export const getPredictionSetToDelete = (state) => state.artificialIntelligence.deletePredictionSet
export const getSelectedAIModelIdForEdit = (state) => state.artificialIntelligence.selectedAIModelIdForEdit
export const getSelectedAiObject = (state) => state.artificialIntelligence.selectedAIObject
export const getSelectedTagForAIObjectAttach = (state) => state.artificialIntelligence.selectedTagForAIObjectAttach
export const getSelectedUniqueAIObject = (state) => state.artificialIntelligence.selectedUniqueAIObject
export const getSelectedUniqueAIObjectAnnotation = (state) =>
  state.artificialIntelligence.selectedUniqueAIObjectAnnotation
export const getUnattachedAIModels = (state) => state.artificialIntelligence.unattachedAIModels
export const getUniqueAIObjectIdForConnectionsEdit = (state) =>
  state.artificialIntelligence.editUniqueAIObjectConnectionsDialog.uniqueAIObjectId
export const getUniqueAIObjects = (state) => state.artificialIntelligence.uniqueAIObjects
export const getVQAModels = (state) => state.artificialIntelligence.vqaModels
export const getVQAModelToDelete = (state) => state.artificialIntelligence.deleteVQAModel

export const getSelectedUniqueAIObjectsAIObject = createSelector(
  [getSelectedUniqueAIObject, getAIObjects],
  (selectedUniqueAIObject, aiObjects) => {
    const placeholder = { Name: '', Properties: [] }

    if (!selectedUniqueAIObject) {
      return placeholder
    }

    return aiObjects.find((aiObject) => aiObject.Id === selectedUniqueAIObject.AIObjectId) || placeholder
  }
)

export const getAIObjectById = createSelector([getAIObjects, (state, { Id }) => Id], (aiObjects, Id) => {
  return aiObjects.find((aiObject) => aiObject.Id === Id)
})

export const getUniqueAIObjectsByAIObjectId = (state, { Id }) => {
  const objects = getUniqueAIObjects(state)[Id]

  return objects || []
}

export const getAIObjectsForSelectedTag = createSelector(
  [getAIObjectsForTags, getSelectedTagForAIObjectAttach],
  (aiObjectsList, selectedTagForAIObjectAttach) => {
    if (!selectedTagForAIObjectAttach) {
      return aiObjectsList
    }

    let attachedToTagButUnavailableForTenant = selectedTagForAIObjectAttach.AIObjects.map((object) => {
      return { ...object, IsAttachedToTag: true }
    })

    const result = aiObjectsList.map((aiObject) => {
      const attachedAIObject = selectedTagForAIObjectAttach.AIObjects.find((ao) => ao.Id === aiObject.Id)
      const isAttached = Boolean(attachedAIObject)

      if (isAttached) {
        attachedToTagButUnavailableForTenant = attachedToTagButUnavailableForTenant.filter(
          (o) => o.Id !== attachedAIObject.Id
        )
      }
      return { ...aiObject, IsAttachedToTag: isAttached }
    })

    return _.sortBy([...result, ...attachedToTagButUnavailableForTenant], 'Name')
  }
)

export const getAIModelForEdit = createSelector(
  [getSelectedAIModelIdForEdit, getPredictionSets],
  (aiModelId, predictionSets) => {
    return predictionSets.find((model) => model.AIModelId === aiModelId)
  }
)

export const getIsModelTrainingEnabled = createSelector(
  [getIsModelTrainingSettingEnabled, getModelTrainingPermission],
  (isSettingEnabled, hasPermission) => {
    return isSettingEnabled && hasPermission
  }
)

export const getContentUniqueAIObjects = (state) => state.artificialIntelligence.contentUniqueAIObjects

export const getUniqueAIObjectsConnections = createSelector([getContentUniqueAIObjects], (uniqueAIObjects) => {
  const connections = []

  for (const uniqueAIObject of uniqueAIObjects) {
    for (const connection of uniqueAIObject.ParentConnections) {
      connections.push({
        Id: connection.Id,
        AIObjectId: connection.AIObjectId,
        ParentId: connection.Parent.Id,
        ChildId: uniqueAIObject.Id,
        Type: connection.Type,
      })
    }

    for (const connection of uniqueAIObject.ChildConnections) {
      connections.push({
        Id: connection.Id,
        AIObjectId: connection.AIObjectId,
        ParentId: uniqueAIObject.Id,
        ChildId: connection.Child.Id,
        Type: connection.Type,
      })
    }
  }

  return _.uniqBy(connections, (c) => c.Id)
})

export const getSelectedPageUniqueAIObjectsWithAnnotations = createSelector(
  [getSelectedPageAnnotations, getAIObjects, getContentUniqueAIObjects],
  (annotations, aiObjects, uniqueAIObjects) => {
    const annotationToIdMap = _.keyBy(annotations, 'Id')
    const aiObjectToIdMap = _.keyBy(aiObjects, 'Id')

    const objects = []

    for (const uniqueAIObject of uniqueAIObjects) {
      const annotation = annotations.find((a) => a.UniqueAIObjectId === uniqueAIObject.Id)
      if (!annotation) {
        continue
      }

      const aiObject = aiObjectToIdMap[annotation.AIObjectId]
      if (!aiObject || aiObject.ObjectLevel === null) {
        continue
      }

      const hotspotAnnotation = annotationToIdMap[annotation.ParentAnnotationId]
      if (!hotspotAnnotation) {
        continue
      }

      const shapeAnnotation = annotationToIdMap[hotspotAnnotation.ParentAnnotationId]
      if (!shapeAnnotation) {
        continue
      }

      const object = {
        id: uniqueAIObject.Id,
        level: aiObject.ObjectLevel,
        aiObject,
        uniqueAIObject,
        text: annotation,
        hotspot: hotspotAnnotation,
        shape: shapeAnnotation,
      }

      objects.push(object)
    }

    return objects
  }
)
